import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-react-intl';

import mq, { mqdown } from '@mq';
import RichText from '@core/RichText';

import Seo from '@core/Seo';

import Button from '@core/Button';
import Image from '@core/Image';
import { hover } from '@mixins';
import TextSliderSectionSmall from '@components/TextSliderSectionSmall';

import illustrations from '@common/illustrations';
import ContactSection from '@components/ContactSection';

import CheckSvg from '@icons/check.svg';
import Link from '@core/Link';
import withLink from '@common/withLink';
import FAQSection from '@components/FAQSection';
import ImageTextSection from '@components/ImageTextSection';

const DeskIllustration = illustrations.desks;

const CoworkingPage = ({
  data: {
    contentfulCoworkingPage: {
      title,
      description,
      eiswerkTitle,
      eiswerkDescription,
      eiswerkDetails,
      eiswerkCallToAction,
      eiswerkImage,
      sketchTitle,
      sketchDescription,
      sketchDetails,
      sketchCallToAction,
      sketchImage,
      textSliderTitle,
      textSlider,
      contactName,
      contactTitle,
      contactInfo,
      contactImage,
      otherSpacesTitle,
      otherSpacesDescription,
      faqTitle,
      faqItems,
    },
  },
}) => (
  <>
    <Seo
      pageTitle={title}
      pageDescription="Searching the best place to work from in Berlin? Quieter than a café and better value than WeWork ;-) Then book a day pass at one of Denizen’s coworking cafes."
    />
    <main>
      <TopSection>
        <Title>{title}</Title>
        <Description text={description} />
      </TopSection>
      <Spaces>
        <EiswerkTitle>{eiswerkTitle}</EiswerkTitle>
        <SpaceImage to="/needspace/coworkingberlin/denizeneiswerk">
          <Image image={eiswerkImage} />
        </SpaceImage>
        <SpaceDescription text={eiswerkDescription} />
        <SpaceDetails>
          {eiswerkDetails.map((detail, index) => (
            <li key={detail.id}>
              <Check />
              <SpaceDetailKey>{detail.key}</SpaceDetailKey>
              <SpaceDetailValue
                content={getLongestText(sketchDetails[index].value, eiswerkDetails[index].value)}
              >
                <div>{detail.value}</div>
              </SpaceDetailValue>
            </li>
          ))}
        </SpaceDetails>
        <SpaceCallToAction to="/needspace/coworkingberlin/denizeneiswerk">
          {eiswerkCallToAction}
        </SpaceCallToAction>
        <SketchTitle>{sketchTitle}</SketchTitle>
        <SpaceImage to="/needspace/coworkingberlin/sketch">
          <Image image={sketchImage} />
        </SpaceImage>
        <SpaceDescription text={sketchDescription} />
        <SpaceDetails>
          {sketchDetails.map((detail, index) => (
            <li key={detail.id}>
              <Check />
              <SpaceDetailKey>{detail.key}</SpaceDetailKey>
              <SpaceDetailValue
                content={getLongestText(sketchDetails[index].value, eiswerkDetails[index].value)}
              >
                <div>{detail.value}</div>
              </SpaceDetailValue>
            </li>
          ))}
        </SpaceDetails>
        <SpaceCallToAction to="/needspace/coworkingberlin/sketch">
          {sketchCallToAction}
        </SpaceCallToAction>
      </Spaces>
      <ImageTextSection title={otherSpacesTitle} text={otherSpacesDescription} leftAlign />
      <TextSliderSectionSmall
        title={textSliderTitle}
        items={textSlider}
        illustration={DeskIllustration}
        context="needspace-coworkingberlin"
      />
      <FAQSection title={faqTitle} faqItems={faqItems} />
      <ContactSection
        contactName={contactName}
        contactTitle={contactTitle}
        contactInfo={contactInfo}
        contactImage={contactImage}
      />
    </main>
  </>
);

const defaultTextColumns = css`
  grid-column: 1 / -1;

  ${mq.medium} {
    grid-column: 2 / -2;
  }

  ${mq.large} {
    grid-column: 3 / -3;
  }

  ${mq.huge} {
    grid-column: 6 / -6;
  }
`;

const spaceColumns = css`
  ${mqdown.small} {
    grid-column: 1 / -1;
  }

  ${mq.small} {
    grid-column-end: span 3;
    margin-left: var(--space-xs);
    margin-right: var(--space-xs);
  }

  ${mq.medium} {
    grid-column-end: span 6;
    margin-left: var(--space-s);
    margin-right: var(--space-s);
  }
`;

const TopSection = styled.section`
  padding-bottom: 0;

  ${mqdown.small} {
    &:first-child {
      padding-top: var(--space-xs);
    }
  }
`;

const Title = styled.h1`
  ${defaultTextColumns};
  margin-bottom: var(--space-xs);

  ${mq.small} {
    margin-bottom: var(--space-s);
  }
`;

const Description = styled(RichText)`
  ${defaultTextColumns};
`;

const Spaces = styled.section`
  ${mqdown.small} {
    padding-top: var(--space-s);
  }
`;

const SpaceTitle = styled.div`
  ${spaceColumns};

  font: var(--font-s);
  margin-bottom: var(--space-xxs);

  ${mq.small} {
    grid-row: 1;
    font: var(--font-l);
    margin-bottom: var(--space-s);
    text-align: center;
  }
`;

const EiswerkTitle = styled(SpaceTitle)`
  ${mqdown.small} {
    grid-row: 2;
  }
`;

const SketchTitle = styled(SpaceTitle)`
  ${mqdown.small} {
    grid-row: 7;
  }
`;

const SpaceImage = withLink(styled.div`
  ${spaceColumns};

  margin-bottom: var(--space-xxs);

  ${mq.small} {
    margin-bottom: var(--space-xs);
    grid-row: 2;
  }

  ${hover`
  opacity: 1;
`}
`);

const SpaceDescription = styled(RichText)`
  ${spaceColumns};

  ${mq.small} {
    grid-row: 3;
    margin-bottom: var(--space-s);
  }
`;

const SpaceDetails = styled.ul`
  ${spaceColumns};
  display: grid;
  margin-bottom: var(--space-s);

  ${mqdown.small} {
    row-gap: var(--space-xxs);
  }

  ${mq.small} {
    grid-row: 4;
    row-gap: var(--space-s);
    align-content: start;
  }

  li {
    ${mq.small} {
      display: grid;
      column-gap: var(--space-xs);
      row-gap: var(--space-xxs);
      justify-content: start;
    }
  }
`;

const SpaceDetailKey = styled.div`
  ${mqdown.small} {
    color: var(--color-grey);
    padding-top: var(--space-xxs);
    margin-top: var(--space-xxs);
    border-top: 0.0625rem solid var(--color-secondary);
    margin-bottom: var(--space-xxxs);
  }

  ${mq.small} {
    font-weight: bold;
    margin-right: 0.5ch;
    grid-column: 2;
    grid-row: 1;
  }
`;

const SpaceDetailValue = styled.div`
  ${mq.small} {
    grid-column: 2;
    grid-row: 2;
    position: relative;

    &::before {
      content: '${props => props.content}';
      opacity: 0;
      visibility: hidden;
      color: transparent;
    }

    > div {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

const Check = styled(CheckSvg)`
  ${mqdown.small} {
    display: none;
  }

  ${mq.small} {
    width: 2.375rem;
    height: 2.375rem;

    grid-column: 1;
    grid-row: 1;
    align-self: center;
  }
`;

const SpaceCallToAction = styled(Button)`
  ${spaceColumns};
  justify-self: center;

  ${mqdown.small} {
    margin-bottom: var(--space-s);
  }

  ${mq.small} {
    grid-row: 5;
  }
`;

function getLongestText(first, second) {
  if (first.length >= second.lenght) {
    return first;
  }
  return second;
}

export const CoworkingPageQuery = graphql`
  query CoworkingPageQuery($locale: String) {
    contentfulCoworkingPage(node_locale: { eq: $locale }) {
      node_locale
      title
      description {
        raw
      }
      eiswerkTitle
      eiswerkDescription {
        raw
      }
      eiswerkDetails {
        key
        value
        id
      }
      eiswerkCallToAction
      eiswerkImage {
        description
        id
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750, 1080]
          formats: [AUTO, WEBP, AVIF]
          aspectRatio: 1.47
          resizingBehavior: FILL
          cropFocus: FACES
          quality: 75
        )
      }
      sketchTitle
      sketchDescription {
        raw
      }
      sketchDetails {
        key
        value
        id
      }
      sketchCallToAction
      sketchImage {
        description
        id
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750, 1080]
          formats: [AUTO, WEBP, AVIF]
          aspectRatio: 1.47
          resizingBehavior: FILL
          cropFocus: FACES
          quality: 75
        )
      }
      otherSpacesTitle
      otherSpacesDescription {
        raw
      }
      textSliderTitle
      textSlider {
        id
        text
      }
      faqTitle
      faqItems {
        id
        title
        text {
          raw
        }
      }
      contactName
      contactTitle
      contactInfo {
        raw
      }
      contactImage {
        description
        id
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750]
          formats: [AUTO, WEBP, AVIF]
          aspectRatio: 1
          resizingBehavior: FILL
          cropFocus: FACES
          quality: 75
        )
      }
    }
  }
`;
// callToActionText
// contactName
// contactTitle
// contactInfo {
//   raw
// }

// sections {
//   ... on ContentfulEntry {
//     ...sections
//   }
// }

export default injectIntl(CoworkingPage);
